<script setup lang="ts">
import { useRoute } from 'vue-router';

function isActive(route: string) {
  return useRoute().path === route;
}
</script>

<template>
  <div class="m-4">
    <nav>
      <NuxtLink
        to="/mon-tableau-de-bord"
        :class="{ active: isActive('/mon-tableau-de-bord') }"
        class="text-darkblue_brs p-4 block md:inline"
        >Accueil</NuxtLink
      >
      <NuxtLink
        to="/mes-charges"
        :class="{ active: isActive('/mes-charges') }"
        class="text-darkblue_brs p-4 block md:inline"
        >Charges badges</NuxtLink
      >
      <NuxtLink
        to="/mes-consommations"
        :class="{ active: isActive('/mes-consommations') }"
        class="text-darkblue_brs p-4 block md:inline"
        >Consommations bornes</NuxtLink
      >
      <NuxtLink
        to="/mes-equipements"
        :class="{ active: isActive('/mes-equipements') }"
        class="text-darkblue_brs p-4 block md:inline"
        >Equipements</NuxtLink
      >
      <!--NuxtLink
        to="/mes-documents"
        :class="{ active: isActive('/mes-documents') }"
        class="text-darkblue_brs p-4 block md:inline"
        >Documents</NuxtLink
      -->
      <NuxtLink
        to="/mon-profil"
        :class="{ active: isActive('/mon-profil') }"
        class="text-darkblue_brs p-4 block md:inline"
        >Profil</NuxtLink
      >
    </nav>
  </div>
</template>

<style scoped>
.active {
  color: #36a9e1;
  border-bottom: 3px solid #36a9e1;
  font-weight: bold;
}
</style>
