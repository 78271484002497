<template>
  <div class="min-h-screen flex flex-col space">
    <BaseHeader
      v-if="
        useRoute().path !== '/' &&
        useRoute().path !== '/motdepasse-oublie' &&
        useRoute().path !== '/renouveler-motdepasse' &&
        useRoute().path !== '/adminasuser'
      "
    />
    <main class="flex-grow">
      <slot />
    </main>
    <BaseFooter
      v-if="
        useRoute().path !== '/' &&
        useRoute().path !== '/motdepasse-oublie' &&
        useRoute().path !== '/renouveler-motdepasse' &&
        useRoute().path !== '/adminasuser'
      "
    />
  </div>
</template>
